import React from 'react';
import { Navigate } from 'react-router-dom';
import { isSignedIn } from '../services/auth-service';

const withAuth = (WrappedComponent) => {
  const AuthenticatedComponent = () => {
    if (isSignedIn()) {
      return <WrappedComponent />;
    } else {
      return <Navigate to="/signin" />;
    }
  }

  return AuthenticatedComponent;
}

export default withAuth;