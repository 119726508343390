import React from 'react';
import Header from '@cloudscape-design/components/header';
import DevPenLogo from "jsx:../images/app-logo.svg";
import './TopBar.scss';

function TopBar({ onLogoClick, actions }) {
  const handleLogoClick = () => {
    if (onLogoClick) {
      onLogoClick();
    }
  };

  return (
    <Header
      variant="h1"
      actions={actions}
    >
      <span onClick={handleLogoClick} style={{ cursor: 'pointer', display: 'inline-block', marginTop: '12px' }}>
        <DevPenLogo />
      </span>
    </Header>
  );
}

export default TopBar;
