import React, { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import {
  HOME_PATH,
  NOTES_PATH,
  NEW_NOTE_PATH,
  NOTE_EDIT_PATH,
  NOTE_DETAILS_PATH,
  SIGNUP_PATH,
  SIGNIN_PATH,
  OTP_VALIDATION_PATH,
  USER_PROFILE,
} from './routes';
import '@cloudscape-design/global-styles/index.css';
import PageLoader from './molecules/PageLoader';
import Profile from './pages/Profile';
import { QueryClient, QueryClientProvider } from 'react-query';

const Home = lazy(() => import('./pages/Home'));
const Notes = lazy(() => import('./pages/Notes'));
const NewNote = lazy(() => import('./pages/NewNote'));
const NoteEdit = lazy(() => import('./pages/NoteEdit'));
const NoteDetails = lazy(() => import('./pages/NoteDetail'));
const SignUp = lazy(() => import('./pages/SignUp'));
const SignIn = lazy(() => import('./pages/SignIn'));
const OTPValidation = lazy(() => import('./pages/OTPValidation'));

const routes = [
  { path: HOME_PATH, element: <Home /> },
  { path: NOTES_PATH, element: <Notes /> },
  { path: NEW_NOTE_PATH, element: <NewNote /> },
  { path: NOTE_EDIT_PATH, element: <NoteEdit /> },
  { path: NOTE_DETAILS_PATH, element: <NoteDetails /> },
  { path: SIGNUP_PATH, element: <SignUp /> },
  { path: SIGNIN_PATH, element: <SignIn /> },
  { path: OTP_VALIDATION_PATH, element: <OTPValidation /> },
  { path: USER_PROFILE, element: <Profile /> },
];

const router = createBrowserRouter(routes);
const queryClient = new QueryClient()

const App = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </Suspense>
  );
};

export default App;
