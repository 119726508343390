import { Box, SpaceBetween, Spinner } from '@cloudscape-design/components';
import Button from '@cloudscape-design/components/button';
import Container from '@cloudscape-design/components/container';
import Header from '@cloudscape-design/components/header';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import withAuth from '../hoc/withAuth';
import withLayout from '../hoc/withLayout';
import { HOME_PATH, USER_PROFILE } from '../routes';
import { signOut } from '../services/auth-service';
import { getUserProfile } from '../services/profile-service';

export const breadCrumbItems = [
    {
        text: 'Profile',
        href: USER_PROFILE,
    },
];

const ValueWithLabel = ({ label, children }) => (
    <div>
        <Box variant="awsui-key-label">{label}</Box>
        <div>{children}</div>
    </div>
);

const Profile = () => {
    const navigate = useNavigate();

    const { data: userProfile, isLoading } = useQuery('userProfile', getUserProfile, {
        staleTime: 5 * 60 * 1000, // Cache profile data for 5 minutes
    });

    const handleSignOutClick = () => {
        signOut();
        navigate(HOME_PATH);
    };

    if (isLoading) {
        return (
            <Container header={<Header variant="h2">Loading Profile...</Header>}>
                <Spinner size="large" />
            </Container>
        );
    }

    const { email = '-', name = '-', plan = '-' } = userProfile || {};

    return (
        <Container
            header={
                <Header
                    variant="h2"
                    description="Your user profile"
                    actions={<Button variant="link" onClick={handleSignOutClick}>Sign out</Button>}
                >
                    Profile
                </Header>
            }
        >
            <SpaceBetween size="l">
                <ValueWithLabel label="Name">{name}</ValueWithLabel>
                <ValueWithLabel label="Email">{email}</ValueWithLabel>
                <ValueWithLabel label="Plan">{plan}</ValueWithLabel>
            </SpaceBetween>
        </Container>
    );
};

export default withLayout(withAuth(Profile), breadCrumbItems);
