import React from 'react';
import Layout from '../containers/Layout';

const withLayout = (WrappedComponent, breadCrumbItems: { href: string; text: string }[] = []) => {
    return () => (
        <Layout breadCrumbItems={breadCrumbItems}>
            <WrappedComponent />
        </Layout>
    );
}

export default withLayout;