import { API_URL } from "../constants";

const AUTH_TOKEN_KEY = 'authToken';

// Utility function to handle API requests
async function apiRequest<T>(url: string, options: RequestInit): Promise<T> {
    try {
        const response = await fetch(url, options);
        
        if (!response.ok) {
            const errorMessage = await response.text();
            throw new Error(errorMessage || 'Request failed');
        }

        return response.json();
    } catch (error) {
        console.error(`API Request Error: ${error}`);
        throw new Error('An error occurred while communicating with the server');
    }
}

// Authentication API functions
export async function signUp({ name, email, password }: { name: string; email: string; password: string; }) {
    const url = `${API_URL}/auth/signup`;
    const body = JSON.stringify({ name, email, password });
    const options: RequestInit = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body,
    };

    return apiRequest(url, options);
}

export async function validateOTP(otp: string, email: string) {
    const url = `${API_URL}/auth/validate-otp`;
    const body = JSON.stringify({ otp, email });
    const options: RequestInit = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body,
    };

    const data = await apiRequest<{ token: string }>(url, options);
    saveAuthToken(data.token);
    return data;
}

export async function signIn(email: string, password: string) {
    const url = `${API_URL}/auth/login`;
    const body = JSON.stringify({ email, password });
    const options: RequestInit = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body,
    };

    const data = await apiRequest<{ token: string }>(url, options);
    saveAuthToken(data.token);
    return data;
}

// Token management functions
export function saveAuthToken(token: string) {
    localStorage.setItem(AUTH_TOKEN_KEY, token);
}

export function getAuthToken(): string | null {
    return localStorage.getItem(AUTH_TOKEN_KEY);
}

export function buildAuthTokenHeader(): string {
    const token = getAuthToken();
    return token ? `Bearer ${token}` : '';
}

export function isSignedIn(): boolean {
    return !!getAuthToken();
}

export function signOut() {
    // TODO: Optionally clear the backend session
    localStorage.removeItem(AUTH_TOKEN_KEY);
}
