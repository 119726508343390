import AppLayout from '@cloudscape-design/components/app-layout';
import Container from '@cloudscape-design/components/container';
import ContentLayout from '@cloudscape-design/components/content-layout';
import Spinner from '@cloudscape-design/components/spinner';
import React from 'react';
import TopBar from './TopBar';
import BreadcrumbGroup from '@cloudscape-design/components/breadcrumb-group';

const PageLoader = () => {

    const topbar = <TopBar
        onLogoClick={() => { }}
        actions={null}
    />

    return (
        <AppLayout content={
            <ContentLayout header={topbar}>
                <Container
                ><div><Spinner /> Loading...</div>
                </Container>
            </ContentLayout>
        } navigationHide toolsHide
            breadcrumbs={<BreadcrumbGroup items={[]} />}
        />
    );
}

export default PageLoader;