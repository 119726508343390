import React, { ReactElement } from "react";
import TopBar from "../molecules/TopBar";
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { HOME_PATH, USER_PROFILE } from '../routes';
import AppLayout from '@cloudscape-design/components/app-layout';
import ContentLayout from '@cloudscape-design/components/content-layout';
import Button from '@cloudscape-design/components/button';
import { isSignedIn } from '../services/auth-service';
import { BreadcrumbGroup, Icon } from "@cloudscape-design/components";

interface LayoutProps {
  children: ReactElement,
  breadCrumbItems: { href: string; text: string }[]
}

const Layout: React.FC<LayoutProps> = ({ children, breadCrumbItems = [] }) => {
  const navigate = useNavigate();
  const params = useParams();

  const navigateToHomePage = () => {
    navigate(HOME_PATH);
  };

  const handleProfileIconClick = () => {
    navigate(USER_PROFILE);
  }

  const handleBreadCrumbNavigation = (event) => {
    event.preventDefault();
    navigate(event.detail.href);
  }

  // TODO: should replace with profile page link
  const topbar = <TopBar
    onLogoClick={navigateToHomePage}
    actions={isSignedIn() ? <Button
      variant="link"
      onClick={handleProfileIconClick}
    >
      <Icon name="user-profile" />
    </Button> : null}
  />

  const breadCrumbItemsWithParams = breadCrumbItems.map(item => ({
    ...item,
    href: generatePath(item.href, params)
  }));

  const breadCrumb = <BreadcrumbGroup
    items={breadCrumbItemsWithParams}
    onClick={handleBreadCrumbNavigation}
  />

  return (
    <AppLayout content={
      <ContentLayout header={topbar}>
        {children}
      </ContentLayout>
    } navigationHide toolsHide
      breadcrumbs={breadCrumbItemsWithParams?.length ? breadCrumb : undefined}
    />
  );
};

export default Layout;