import { API_URL } from "../constants";
import { buildAuthTokenHeader } from "./auth-service";

export async function getUserProfile() {
    const response = await fetch(`${API_URL}/profile`, {
        method: 'GET',
        headers: {
            authorization: buildAuthTokenHeader()
        }
    });
    const profile = await response.json();
    return profile;
}